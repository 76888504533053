import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Location } from '@angular/common';
import { NavigationExtras, Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(
    public nav: NavController,
    public location: Location,
    public router: Router
  ) {}

  public gotopage(path) {
    try {
      this.nav.navigateForward(path);
      // this.router.navigate(path);
    } catch (error) {
      const message = 'Error - 500034';
      const details =
        'NavigationService gotopage error: ' + JSON.stringify(error);
    }
  }
  public goToNavigateForword(path,data){

    let navigationExtras: NavigationExtras = {
      queryParams: data
    };
    this.nav.navigateForward(path, navigationExtras);
  }
  public gotopageWithoutAnimation(path) {
    this.nav.navigateForward(path, { animated: false });
  }

  public gobacktopage(path) {
    this.nav.navigateBack(path);
  }

  public setRoot(path) {
    this.nav.navigateRoot(path);
  }

  public goBack() {
    this.nav.pop();
  }

  public goToRoot(page) {
    this.nav.navigateRoot(page);
  }
}
