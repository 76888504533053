import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private readonly prefix: string;
  private readonly AUTH_TOKEN = 'session_id';
  private readonly TRACK_NUMBER = 'trackNumber';
  private readonly DEVICE_ID = 'deviceId';

  constructor() {
    this.prefix = 'richDad';
  }

  public setAuthToken(value: string) {
    return this.setItem(this.AUTH_TOKEN, value);
  }

  public getAuthToken() {
    return this.getItem(this.AUTH_TOKEN);
  }

  public removeAuthToken() {
    return this.removeItem(this.AUTH_TOKEN);
  }

  public setTrackNumber(value: string) {
    return this.setItem(this.TRACK_NUMBER, value);
  }

  public getTrackNumber() {
    return this.getItem(this.TRACK_NUMBER);
  }

  public removeTrackNumber() {
    return this.removeItem(this.TRACK_NUMBER);
  }

  public setDeviceId(value: string) {
    return this.setItem(this.DEVICE_ID, value);
  }

  public getDeviceId() {
    return this.getItem(this.DEVICE_ID);
  }

  public removeDeviceId() {
    return this.removeItem(this.DEVICE_ID);
  }

  public setItem(prop: any, value: any) {
    const key = `${this.prefix}-${prop}`;
    localStorage.setItem(key, JSON.stringify(value));
  }

  public getItem(prop: any) {
    const key = `${this.prefix}-${prop}`;
    return JSON.parse(localStorage.getItem(key));
  }

  public removeItem(prop: any) {
    const key = `${this.prefix}-${prop}`;
    return localStorage.removeItem(key);
  }
}
