import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/common/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./beforeLoginPages/login/login.module').then( m => m.LoginPageModule),
  },
  {
    path: 'signup',
    loadChildren: () => import('./beforeLoginPages/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'tab1',
    loadChildren: () => import('./tab1/tab1.module').then(m => m.Tab1PageModule),
    // canActivate:[AuthGuard]
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./beforeLoginPages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./beforeLoginPages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'spacial-code',
    loadChildren: () => import('./beforeLoginPages/spacial-code/spacial-code.module').then( m => m.SpacialCodePageModule)
  },
  {
    path: 'about-us',
    loadChildren: () => import('./commonpages/about-us/about-us.module').then( m => m.AboutUsPageModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./commonpages/contact-us/contact-us.module').then( m => m.ContactUsPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./commonpages/change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./commonpages/help/help.module').then( m => m.HelpPageModule)
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./commonpages/edit-profile/edit-profile.module').then( m => m.EditProfilePageModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./commonpages/notification/notification.module').then( m => m.NotificationPageModule)
  },
  {
    path: 'password-changed',
    loadChildren: () => import('./modals/password-changed/password-changed.module').then( m => m.PasswordChangedPageModule)
  },
  {
    path: 'incorrect-email',
    loadChildren: () => import('./beforeLoginPages/incorrect-email/incorrect-email.module').then( m => m.IncorrectEmailPageModule)
  },
  {
    path: 'incorrect-code',
    loadChildren: () => import('./beforeLoginPages/incorrect-code/incorrect-code.module').then( m => m.IncorrectCodePageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./tabPages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'lessons',
    loadChildren: () => import('./pages/lessons/lessons.module').then( m => m.LessonsPageModule)
  },
  {
    path: 'videos',
    loadChildren: () => import('./pages/videos/videos.module').then( m => m.VideosPageModule)
  },
  {
    path: 'nerve-blocks',
    loadChildren: () => import('./pages/nerve-blocks/nerve-blocks.module').then( m => m.NerveBlocksPageModule)
  },
  {
    path: 'truncal',
    loadChildren: () => import('./pages/truncal/truncal.module').then( m => m.TruncalPageModule)
  },
  {
    path: 'electro-spinae-block',
    loadChildren: () => import('./pages/electro-spinae-block/electro-spinae-block.module').then( m => m.ElectroSpinaeBlockPageModule)
  },
  {
    path: 'view-notes',
    loadChildren: () => import('./pages/view-notes/view-notes.module').then( m => m.ViewNotesPageModule)
  },
  {
    path: 'new-notes',
    loadChildren: () => import('./pages/new-notes/new-notes.module').then( m => m.NewNotesPageModule)
  },
  {
    path: 'note-name',
    loadChildren: () => import('./pages/note-name/note-name.module').then( m => m.NoteNamePageModule)
  },
  {
    path: 'head-and-neck',
    loadChildren: () => import('./pages/head-and-neck/head-and-neck.module').then( m => m.HeadAndNeckPageModule)
  },
  {
    path: 'rx',
    loadChildren: () => import('./pages/rx/rx.module').then( m => m.RxPageModule)
  },
  {
    path: 'non-opiods',
    loadChildren: () => import('./pages/non-opiods/non-opiods.module').then( m => m.NonOpiodsPageModule)
  },
  {
    path: 'drug-name',
    loadChildren: () => import('./pages/drug-name/drug-name.module').then( m => m.DrugNamePageModule)
  },
  {
    path: 'notes',
    loadChildren: () => import('./pages/notes/notes.module').then( m => m.NotesPageModule)
  },
  {
    path: 'nerve-block-list',
    loadChildren: () => import('./pages/nerve-block-list/nerve-block-list.module').then( m => m.NerveBlockListPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./commonpages/faq/faq.module').then( m => m.FaqPageModule)
  },
  {
    path: 'partners',
    loadChildren: () => import('./commonpages/partners/partners.module').then( m => m.PartnersPageModule)
  },
  {
    path: 'disclaimer',
    loadChildren: () => import('./commonpages/disclaimer/disclaimer.module').then( m => m.DisclaimerPageModule)
  },
  {
    path: 'tac',
    loadChildren: () => import('./commonpages/tac/tac.module').then( m => m.TacPageModule)
  },
  {
    path: 'all-videos',
    loadChildren: () => import('./pages/all-videos/all-videos.module').then( m => m.AllVideosPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'partner-contact',
    loadChildren: () => import('./commonpages/partner-contact/partner-contact.module').then( m => m.PartnerContactPageModule)
  },
  {
    path: 'subscription',
    loadChildren: () => import('./pages/subscription/subscription.module').then( m => m.SubscriptionPageModule)
  },
  {
    path: 'terms-policy',
    loadChildren: () => import('./modals/terms-policy/terms-policy.module').then( m => m.TermsPolicyPageModule)
  },
  {
    path: 'download-progress',
    loadChildren: () => import('./modals/download-progress/download-progress.module').then( m => m.DownloadProgressPageModule)
  },
  {
    path: 'privacypolicy',
    loadChildren: () => import('./commonpages/privacypolicy/privacypolicy.module').then( m => m.PrivacypolicyPageModule)
  },
  {
    path: 'rx-sub-page',
    loadChildren: () => import('./pages/rx-sub-page/rx-sub-page.module').then( m => m.RxSubPagePageModule)
  },  {
    path: 'maintenance',
    loadChildren: () => import('./beforeLoginPages/maintenance/maintenance.module').then( m => m.MaintenancePageModule)
  },





  // {
  //   path: 'neck-head',
  //   loadChildren: () => import('./pages/neck-head/neck-head.module').then( m => m.NeckHeadPageModule)
  // }


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
