import { CUSTOM_ELEMENTS_SCHEMA, Component, Input, NO_ERRORS_SCHEMA, OnInit } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { NavController } from '@ionic/angular';
import { DatabaseFunctionsService } from 'src/app/services/database-functions.service';
import { StorageService } from 'src/app/services/storage.service';
import { ApiService } from '../../services/api.service';
import {matchSorter} from 'match-sorter'
import { Events } from 'src/app/services/common/events.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  posts: any = [];
  searched_content: any = [];
  search_keyword: any;
  ready:boolean=true;
  constructor(
    public nav: NavController,
    public api: ApiService,
    public storageService: StorageService,
    public db: DatabaseFunctionsService,
    public events:Events
  ) {
    this.events.subscribe('check_keyword',res=>{
      this.ionViewWillEnter();
    })
  }

  ngOnInit() {}

  ionViewWillEnter(){
    this.storageService.getFromNativeStorage('keyword').then(res=>{
      if(res)
      console.log(res);
      this.search_keyword=res?.keyword;
    })
  }

  handleChange(event) {
    console.log(event)
    this.searched_content = [];
    if(event.detail){
      const query = event.detail.value.toLowerCase();
      console.log(typeof query);
      
      if (query!=='') {
        this.ready=false;
        this.storageService.saveToNativeStorage('keyword',JSON.stringify({keyword:event.detail.value}));
        this.api.getData('get_posts_search?search='+query).subscribe((res:any)=>{
          this.searched_content = [];
          this.ready=true;
          let data=this.getItems(res,query)
          if(data.length>0){
            this.searched_content=data;
          } else {
            this.searched_content=res;
          }
          console.log(res,this.searched_content);
        },err=>{
          // this.ready=true;
          console.log(err);
        })
      }
    }
  }

  getItems = (data,value) => {
    return value
      ? matchSorter(data, value, {
          keys: ['title','category_name','sections'],
        })
      : data
  }

  clear(ev:any){
    this.search_keyword='';
    this.storageService.removeFromNativeStorage('keyword');
    this.searched_content()
  }

  // sort_array(res3) {
  //   this.searched_content = res3.reduce((unique, o) => {
  //     if (!unique.some((obj) => obj === o)) {
  //       unique.push(o);
  //     }
  //     return unique;
  //   }, []);
  // }

  chooseItem(lesson) {
    if (lesson.post_type === 'lessons') {
      let navigationExtras: NavigationExtras = {
        state: {
          post_id: lesson.ID,
          search_keyword: this.search_keyword,
        },
      };
      this.nav.navigateForward('/truncal', navigationExtras);
    } else {
      let navigationExtras: NavigationExtras = {
        state: {
          post_id: lesson.ID,
          search_keyword: this.search_keyword,
        },
      };
      this.nav.navigateForward('non-opiods', navigationExtras);
    }
  }
}
