import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AlertController, LoadingController, NavController, Platform, ToastController } from '@ionic/angular';
import { BehaviorSubject, Subject, Observable, Observer, fromEvent, merge, of } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import $ from 'jquery';
import { Events } from './common/events.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

// const mainUrl = 'https://myblockbuddy.com/';
// const apiV1 = 'https://myblockbuddy.com/wp-json/mobileapi/v1/';

const mainUrl = environment.baseURL;
const apiV1 = environment.baseURL + 'wp-json/mobileapi/v1/';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  loading: any;
  userLoginSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  totalPosts = null;
  pages: any;
  public appIsOnline$: Observable<boolean> = undefined;
  checkInterent: boolean;

  constructor(
    private http: HttpClient,
    private platform: Platform,
    public nav: NavController,
    public alertCtrl: AlertController,
    public toastController: ToastController,
    public loadingCtrl: LoadingController,
    private network: Network,
    public events: Events,
    public router: Router
  ) {

  }

  ngOnInit() {
    this.checkInternetFunc().subscribe(isOnline => {
      this.checkInterent = isOnline
      console.log('this.checkInterent', this.checkInterent)

      // checking internet connection
      if (this.checkInterent == true) {
        // show success alert if internet is working
        // alert('Internet is working.')
        console.log('Internet is working.')
      }
      else {
        // show danger alert if net internet not working
        // alert('Internet is slow or not working.')
        console.log('Internet is slow or not working.')
      }
    });
  }


  getURL() {
    return mainUrl;
  }

  async dismissLoading() {
    if (this.loading) {
      await this.loading.dismiss();
    }
  }

  async showLoader(msg: string = '') {
    if (msg === '') {
      msg = 'Please wait...';
    }
    this.loading = await this.loadingCtrl.create({ message: msg });
    await this.loading.present();
  }

  getData(endPoint) {
    // if(this.network.type){
    return this.http.get(apiV1 + endPoint + '&timestamp=' + new Date().getTime()).pipe(map((data) => data));
    // } else {
    //   this.presentToast('You are offline');
    // }
  }

  sendData(endPoint, data) {
    // if(this.network.type){
    return this.http.post(apiV1 + endPoint + '?timestamp=' + new Date().getTime(), data).pipe(map((result) => result));
    // } else {
    //   this.presentToast('You are offline');
    // }
  }

  doReset(email) {
    return this.http.post(mainUrl + 'forgotPassword' + '?timestamp=' + new Date().getTime(), {
      email: email
    }).pipe(
      map(user => {
        return user;
      })
    )
  }

  getAbout(api = 'get_about_us') {
    return this.http.get(mainUrl + api + '?timestamp=' + new Date().getTime());
  }

  doLogin(email, password, is_agree) {
    return this.http.post(mainUrl + 'wp-json/jwt-auth/v1/token?is_agree=' + is_agree + '&timestamp=' + new Date().getTime(), {
      username: email,
      password: password,
      jw_auth_sec: "wivxCNm$<(+WFwivxCNm$<(+WF#7}1]TWMUl7OaU*TxS(r*$#7}1]wivxCNm$<(+WF#7}1]TWMUl7OaU*TxS(r*$TWMUl7OaU*TxS(r*$"
    })
  }

  async SaveAutoConfiqure(token) {
    if (this.platform.is('cordova')) {
    }
  }

  close_menu() {
    if (this.router.url === '/profile' || this.router.url === '/about-us' || this.router.url === '/about-us' || this.router.url === '/faq' || this.router.url === '/contact-us' || this.router.url === '/partners' || this.router.url === '/disclaimer' || this.router.url === '/tac' || this.router.url === '/subscription') {
      if (!this.platform.is('desktop')) {
        this.events.publish('menu_open', 1);
      }
    }
    this.nav.back();
  }

  async presentAlert(msg, header = null) {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: header ? header : '',
      // subHeader: msg,
      message: msg,
      buttons: ['OK'],
    });

    await alert.present();
  }

  async presentToast(text, postion = null) {
    const toast = await this.toastController.create({
      message: text,
      position: !postion ? 'bottom' : postion,
      duration: 3000,
      cssClass: 'toast_class'
    });
    toast.present();
  }

  checkInternetFunc() {
    if (!window || !navigator || !('onLine' in navigator)) return;

    this.appIsOnline$ = Observable.create(observer => {
      observer.next(true);
    }).pipe(mapTo(true));

    if (this.platform.is('cordova')) {
      // on Device - when platform is cordova
      this.appIsOnline$ = merge(
        this.network.onConnect().pipe(mapTo(true)),
        this.network.onDisconnect().pipe(mapTo(false))
      );
    } else {
      // on Browser - when platform is Browser
      this.appIsOnline$ = merge(
        of(navigator.onLine),
        fromEvent(window, 'online').pipe(mapTo(true)),
        fromEvent(window, 'offline').pipe(mapTo(false))
      );
    }

    return this.appIsOnline$
  }

  active(user_id) {
    return firebase.database().ref("is_active" + '/' + user_id).update({ status: 1, active_timestamp: Date.now(), in_active_timestamp: null });
  }

  inactive(user_id) {
    return firebase.database().ref("is_active" + '/' + user_id).update({ status: 0, in_active_timestamp: Date.now() });
  }

  fileValidation(fileInput, type) {
    //this.successToast(fileInput.value);
    var filePath = fileInput.value;
    if (type === 'image') {
      var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    }
    // else {
    //     var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.webm|\.mkv|\.flv|\.vob|\.avi|\.mov|\.wmv|\.mp4|\.m4p|\.mpeg|\.3gp)$/i;
    // }
    if (!allowedExtensions.exec(filePath)) {
      this.presentToast('Please Upload ' + type + ' only');
      return false;
    } else {
      return true;
    }
  }

  is_modile_device() {
    return this.platform.is('cordova');
  }


  external_links(endPoint) {
    // if(this.network.type){
    return this.http.get(endPoint + '?timestamp=' + new Date().getTime()).pipe(map((data) => data));
    // } else {
    //   this.presentToast('You are offline');
    // }
  }

  
  external_links1(endPoint) {
    // if(this.network.type){
    return this.http.get(endPoint);
    // } else {
    //   this.presentToast('You are offline');
    // }
  }

  updateToken(userId, deviceID, deviceData, status) {
    return this.http.post(apiV1 + "updateDeviceToken" + '?timestamp=' + new Date().getTime(), {
      userid: userId,
      deviceID: deviceID,
      deviceData: deviceData,
      status: status
    }).pipe(map(res => {
      return res;
    }));
  }

  async InfoToast(text, postion: any = 'bottom', cssClasses) {
    const toast = await this.toastController.create({
      message: text,
      position: postion,
      duration: 3000,
      cssClass: cssClasses
    });
    toast.present();
  }


  // added by rupali
  validateAANAUser(token) {
    return this.http.post(apiV1 + "validate_aana_user_pwa", {
      code: token,
    }).pipe(map((result) => result));
  }
}
