import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PrefService } from './services/common/pref.service';
import { StorageEngineService } from './services/common/storage-engine.service';
import { LocalStorageService } from './services/common/local-storage.service';
import { UtilsService } from './services/common/utils.service';
import { SplashScreen } from "@awesome-cordova-plugins/splash-screen/ngx";
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { SQLite, SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { PreviewAnyFile } from '@awesome-cordova-plugins/preview-any-file/ngx';
import { PartnerContactPageModule } from './commonpages/partner-contact/partner-contact.module';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { TermsPolicyPageModule } from './modals/terms-policy/terms-policy.module';
import { FileTransfer} from '@ionic-native/file-transfer/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { NgProgressModule } from "ngx-progressbar";
import { NgProgressHttpModule } from "ngx-progressbar/http";
import { DocumentViewer } from '@ionic-native/document-viewer/ngx';
import { environment } from 'src/environments/environment';

import { FormsModule } from '@angular/forms';
import { SharedModule } from './components/shared.module';
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    SharedModule,
    HttpClientModule, 
    NgProgressModule.withConfig({
      spinnerPosition: "left",
      color: "#26a9e0",
      spinner:false,
      thick:true,
      meteor:true,
      fixed:false,
      ease: 'linear',
      min: 8,
      max: 100,
    }),
    NgProgressHttpModule,
    IonicModule.forRoot({
      mode:"ios"
    }), 
    AppRoutingModule,
    PartnerContactPageModule,
    TermsPolicyPageModule,
    FormsModule
  ],
  providers: [
    SplashScreen,
    StatusBar,
    NativeStorage,
    CallNumber,
    StorageEngineService,
    LocalStorageService,
    UtilsService,
    File,
    InAppBrowser,
    PrefService,
    SQLite,
    Network,
    PreviewAnyFile,
    OneSignal,
    FileTransfer,
    ScreenOrientation,
    DocumentViewer,
    Device,
    //  {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true},
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule {}
