import { Component, ViewChild } from '@angular/core';
import { MenuController, ModalController, IonProgressBar, Platform, AlertController, } from '@ionic/angular';
import { NavigationService } from './services/navigation.service';
import { StorageService } from './services/storage.service';
import { Events } from './services/common/events.service';
import { ApiService } from './services/api.service';
import firebase from 'firebase/compat/app';
import { Router } from '@angular/router';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { DownloadDataService } from './services/download-data.service';
import { DownloadFilesService } from './services/download-files.service';
import $ from 'jquery';
import { AlertControllerService } from './services/alert-controller.service';
import { InAppBrowserOptions, InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  @ViewChild('ionPB') elemPB: IonProgressBar;
  user: any;
  deviceData: any = [];
  public buffer = 0.06;
  public progress = 0;
  lessons: any = [];
  interval: any;
  public browser: any
  menuItems = [
    {
      title: 'Profile',
      url: '/profile',
    },
    {
      title: 'About Us',
      url: '/about-us',
    },
    {
      title: 'Contact Us',
      url: '/contact-us',
    },
    // {
    //   title: 'Partners',
    //   url: '/partners',
    // },
    {
      title: 'Terms of Use',
      url: '/tac',
    },
    {
      title: 'Privacy Policy',
      url: '/privacypolicy',
    },
    {
      title: 'Medical Disclaimer',
      url: '/disclaimer',
    },
    // {
    //   title: 'Manage Subscription',
    //   url: 'subscription',
    // },
  ];
  total_posts_page: any;
  page_no: any = 1;
  isProgressOpen: boolean = false;
  startedClass = false;
  completedClass = false;
  logs: any[];
  last_updated: any;
  assets: any = [];
  downloading_status: any;
  current_progress: any = 0;
  found_posts: any = 0;
  constructor(
    public apiService: ApiService,
    private storageService: StorageService,
    private naviGation: NavigationService,
    private menu: MenuController,
    public model: ModalController,
    public events: Events,
    public router: Router,
    public device: Device,
    public download_contents: DownloadDataService,
    public downloadfile: DownloadFilesService,
    public platform: Platform,
    public alertControllerService: AlertControllerService,
    public alertCtrl: AlertController,
    public iab: InAppBrowser
  ) {

    this.events.subscribe('User:LoggedIn', (res) => {
      this.redirectUser();
    });

    this.events.subscribe('menu_open', (res) => {
      this.menu.toggle();
    });

    this.initializeApp();

    setInterval(() => {
      this.buffer += 0.06;
      this.progress += 0.06;

      // Reset the progress bar when it reaches 100%
      // to continuously show the demo
      if (this.progress > 1) {
        setTimeout(() => {
          this.buffer = 0.06;
          this.progress = 0;
        }, 1000);
      }
    }, 1000);
  }

  initializeApp() {
    var firebaseConfig = {
      apiKey: 'AIzaSyDrwe9-vlW51_vJEDd70eqyPyWXSMrQjRs',
      authDomain: 'blockbuddy-2420d.firebaseapp.com',
      databaseURL: 'https://blockbuddy-2420d-default-rtdb.firebaseio.com',
      projectId: 'blockbuddy-2420d',
      storageBucket: 'blockbuddy-2420d.appspot.com',
      messagingSenderId: '154587699172',
      appId: '1:154587699172:web:fc85dfb2b8d500871299f1',
    };
    const app = firebase.initializeApp(firebaseConfig);
    this.redirectUser()
    if (this.platform.is('desktop')) {
      this.menu.toggle();
    }
  }
  
  async redirectUser() {
    this.storageService.getFromNativeStorage('bb:user').then((user) => {
      this.user = user;
      if (user != null) {
        this.menu.swipeGesture(false);
        this.menu.enable(true);
        this.naviGation.goToRoot('/home');
        // added by rupali
      } else {
        const code = this.platform.getQueryParam('code')
        if (code) {
          this.tokenAlert(code);
          this.naviGation.goToRoot('/login');
          this.menu.enable(false);
          // -------------end------------------
        } else {
          this.menu.enable(false);
          // this.naviGation.goToRoot('/maintenance');
          this.naviGation.goToRoot('/login');

        }
      }
    });
  }


  // added by rupali
  async tokenAlert(token) {
    if (token) {
      this.apiService.showLoader();
      this.apiService.validateAANAUser(token).subscribe(res => {
        this.naviGation.goToRoot('/login');
        this.menu.enable(false);
        this.apiService.dismissLoading();
        if (res['user_id']) {
          this.apiService.dismissLoading();
          if (res['role'] !== 'administrator') {
            if (!res['is_subscribed']) {
              console.log('Not subscribe yet');
              // this.alertControllerService.redirect_to_website(res['subscription_message']);
              this.alertControllerService.subscriptionAlert();
              return false;
            }
            if (res['next_time_period']) {
              var date = new Date(res['next_time_period']);
              if (new Date(date.toDateString()) < new Date(new Date().toDateString())) {
                console.log('next subscription date gone');
                console.log('Next Billing Date', new Date(date.toDateString()));
                console.log('Today date', new Date(new Date().toDateString()));
                // this.alertControllerService.redirect_to_website(res['subscription_message']);
                this.alertControllerService.subscriptionAlert();
                return false;
              }
            }
          }
          this.storageService.saveToNativeStorage('bb:user', JSON.stringify(res));
          this.menu.enable(true);
          var self = this;
          this.events.publish('User:LoggedIn', 1);
        } else {
          this.naviGation.goToRoot('/login');
          this.menu.enable(false);
          this.Back();
        }
      },
        (err) => {
          console.log(err);
          this.apiService.dismissLoading();
          this.naviGation.goToRoot('/login');
          this.menu.enable(false);
          this.Back();
        }
      );
    }
  }


  // added by rupali
  async Back() {
    const confirm = await this.alertCtrl.create({
      header: 'Login Unsuccessful:',
      message: "We couldn't log you in with the credentials provided. Please <a href='mailto:help@aana.com'>contact support</a> for further assistance.",
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            console.log('Confirm Okay.');
            const options: InAppBrowserOptions = {
              location: "yes",
              fullscreen: "yes",
              toolbarposition: "top",
              closebuttoncaption: "Cancel",
              clearcache: "yes",
              clearsessioncache: "yes",
              hidden: "no",
              hideurlbar: "no",
              toolbar: "yes",
              hidenavigationbuttons: "no",
              zoom: "yes",
            };
            this.browser = this.iab.create(this.apiService.getURL(), '_blank', options);
            this.browser.show();
          }
        }
      ]
    });
    await confirm.present();
  }

  logout() {
    this.cancel_model();
    this.apiService.inactive(this.user.user_id);
    this.storageService.removeFromNativeStorage('bb:user');
    this.menu.enable(false);
    this.removeToken(this.user.user_id);
    this.naviGation.setRoot('/login');
  }

  close_menu() {
    this.menu.close();
  }

  cancel_model() {
    this.model.dismiss();
  }

  removeToken(user_id) {
    this.deviceData.push({
      uuid: this.device.uuid,
      model: this.device.model,
      platform: this.device.platform,
      version: this.device.version,
    });
    this.apiService
      .updateToken(user_id, '', this.deviceData, 'logout')
      .subscribe((res) => { });
  }

  close_model() {
    this.isProgressOpen = false;
  }

  onStarted() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  view_pages(url) {
    if (!url) {
      this.isProgressOpen = true;
    } else {
      this.router.navigate([url]);
    }
  }

  get_web_url() {
    this.apiService.showLoader();
    this.storageService.getFromNativeStorage('bb:user').then((user) => {
      if (user !== null) {
        this.apiService.getData('generate_session_token?token=' + user.token).subscribe(res => {
          this.apiService.dismissLoading();
          window.open('https://myblockbuddy.com/my-account/?one_time_token=' + res + '&id=' + user.user_id, '_blank');
        }, err => {
          this.apiService.dismissLoading();
          console.log(err);
        })
      }
    })
  }

  menu_toggle() {
    if (this.platform.is('mobile')) {
      this.menu.toggle();
    } else {
      const splitPane = document.querySelector('ion-split-pane');
      const windowWidth = window.innerWidth;
      const splitPaneShownAt = 992;
      const when = `(min-width: ${splitPaneShownAt}px)`;
      if (windowWidth >= splitPaneShownAt) {
        // split pane view is visible
        const open = splitPane.when === when;
        splitPane.when = open ? false : when;
        this.events.publish('menu_refresh', open);
      } else {
        // split pane view is not visible
        // toggle menu open
        this.events.publish('menu_refresh', true);
        const menu = splitPane.querySelector('ion-menu');
        return menu.open();
      }
    }
  }
}
