import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ModalController } from '@ionic/angular';
import { StorageService } from 'src/app/services/storage.service';
import $ from 'jquery';

@Component({
  selector: 'app-terms-policy',
  templateUrl: './terms-policy.page.html',
  styleUrls: ['./terms-policy.page.scss'],
})

export class TermsPolicyPage implements OnInit {
  @ViewChild('contents', { static: false }) private contents: any;
  content:any;
  page_no:any;
  ready:boolean=false;
  constructor(
    public modal : ModalController, 
    public api : ApiService,
  ) { }

  ngOnInit() {
    console.log(this.page_no)
    this.getContent()
  }

  getContent(){
    this.ready=false;
    this.api.getAbout('wp-json/wp/v2/pages/'+this.page_no).subscribe(res=>{
      this.ready=true;
      let data:any=res;
      this.content=data?.content?.rendered;
      var self=this;
      setTimeout(function(){
        $(document).ready(function(){
          $('a').each(function() {
            var a = new RegExp('/' + window.location.host + '/');
            console.log(a);
            if(this.href.substr(0,7)!='mailto:' && this.href.substr(0,18)!='https://myblockbuddy') {
              $(this).attr('href','javascript:void(0);').on('click',function(){
                console.log($(this).text());
                self.searchText($(this).text())
              })
            }
          })
        })
      },400);
    },err=>{
      this.ready=true;
      console.log(err);
    })
  }

  searchText(text) {
    var self=this;
    var contain=$('ion-text p:contains("'+text+'")')
    if(contain.length==2){
      var pos= $(contain[1]).position();
      self.contents.scrollToPoint(pos.top,pos.top,1500);
    }
  }
}
