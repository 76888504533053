import { Injectable } from '@angular/core';
import { SQLite, SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { Platform } from '@ionic/angular';
import { ApiService } from './api.service';
import { DownloadDataService } from './download-data.service';


@Injectable({
  providedIn: 'root'
})
export class DatabaseFunctionsService {
  db: SQLiteObject;
  constructor(
    private sqlite: SQLite,
    public plt : Platform,
    public apiService : ApiService,
    public download_contents : DownloadDataService
  ) { 
    this.plt.ready().then(() => {
      if(this.plt.is('cordova')){
        this.sqlite.create({
            name: 'block_buddy.db',
            location: 'default'
        }).then((db: SQLiteObject) => {
              this.db = db;
              this.createTables();
        })
        .catch((e) => {
        }); 
      }
    });
  }

  createTables(){ 
    return new Promise((resolve,reject)=>{
      if(this.plt.is('cordova')){
        // lesson master table creation
        this.db.executeSql('CREATE TABLE IF NOT EXISTS bb_other_content (id INTEGER PRIMARY KEY AUTOINCREMENT, title text, content longtext)', []).then(() => {
        }).catch((e) => {reject("bb_notes createTable(error in service):"+ e); });
  
        this.db.executeSql('CREATE TABLE IF NOT EXISTS bb_categories (term_id  bigint(20),name text ,taxonomy  bigint(20), slug  bigint(20),count bigint(20), term_taxonomy_id bigint(20),parent bigint(20), is_auto_expand bigint(20), category_order bigint(20))', []).then(() => {
        }).catch((e) => {reject("bb_notes createTable(error in service):"+ e); });
  
  
        this.db.executeSql('CREATE TABLE IF NOT EXISTS bb_lessons (ID bigint(20), category_id bigint(20), category_name text, category_parent bigint(20), feature_image longtext, is_favour bigint(20), lesson_content_type text, overlay_image boolean, pdf longtext, post_date longtext, post_type text, sections longtext , title text, videos longtext,menu_order bigint(20))', []).then(() => {
        }).catch((e) => {reject("bb_favorites createTable(error in service):"+ e); });
  
        
        this.db.executeSql('CREATE TABLE IF NOT EXISTS bb_favorites (id INTEGER PRIMARY KEY AUTOINCREMENT, user_id bigint(20), post_id bigint(20), status bigint(20))', []).then(() => {
        }).catch((e) => {reject("bb_favorites createTable(error in service):"+ e); });
  
        this.db.executeSql('CREATE TABLE IF NOT EXISTS bb_notes (id INTEGER PRIMARY KEY AUTOINCREMENT, note_id text, user_id bigint(20),post_id bigint(20), category_id bigint(20),category_name varchar(255), title varchar(255), content longtext, lesson_name longtext)', []).then(() => {
        }).catch((e) => {reject("bb_notes createTable(error in service):"+ e); });
  
  
        this.db.executeSql('CREATE TABLE IF NOT EXISTS bb_local_images (id INTEGER PRIMARY KEY AUTOINCREMENT,parent_id bigint(20), post_id varchar(255), url longtext)', []).then(() => {
        }).catch((e) => {reject("bb_local_images createTable(error in service):"+ e); });
  
        this.db.executeSql('CREATE TABLE IF NOT EXISTS bb_download_logs (id INTEGER PRIMARY KEY AUTOINCREMENT,messages longtext)', []).then(() => {
        }).catch((e) => {reject("bb_download_logs createTable(error in service):"+ e); });
      }
    })
  }

  deleteData(table_name,where){
      // this.db.executeSql('Delete from bb_favorites', []).then((data : any) =>{
      // });
      return new Promise((resolve,reject)=>{
        console.log('Delete from '+table_name+' '+where);
        this.db.executeSql('Delete from '+table_name+' '+where, []).then((data : any) =>{
          resolve('Deleted');
        },err=>{
          reject('Not Deleted table data '+table_name+' '+JSON.stringify(err));
        });  
      })
  }

  deleteTables(){
    if(this.plt.is('cordova')){
      this.db.executeSql('DROP TABLE IF EXISTS bb_favorites', []).then((data : any) =>{
      });

      this.db.executeSql('DROP TABLE IF EXISTS bb_notes', []).then((data : any) =>{
      });
    }
  }

  get_other_table(title){
    return new Promise((resolve, reject) =>{
    this.db.executeSql('SELECT * from bb_other_content where title="'+title+'"',[]).then((data : any) =>{
      let push_data:any=[];
      for(var x = 0; x < data.rows.length; x++) {
        push_data.push(data.rows.item(x));
      }
      resolve(push_data);
    }).catch((error : any) =>{
      reject('table creation'+JSON.stringify(error));
    }); 
  });
  }

  add_update_other_data(data){
    return new Promise((resolve, reject) =>{
      this.get_other_table(data.title).then((data : any) =>{
        if(data.length<=0){
          this.db.executeSql('INSERT INTO bb_other_content (title, content) VALUES (?, ?)', [data.title, data.content]).then(data=>{
            resolve(data.title+' Added');
          },err=>{
            reject('Insert Other table error : '+err);
          })
        } else {
          this.db.executeSql('UPDATE bb_other_content SET content=? where title=?', [data.content,data.title]).then(data=>{
            resolve(data.title+' Updated');
          },err=>{
            reject('Update Other table error : '+err);
          })
        }
      })
    })
  }

  // check categories with conditions 
  getCategories(where=''){
    if(this.plt.is('cordova')){
      return new Promise((resolve, reject) =>{
        this.db.executeSql('SELECT * FROM bb_categories '+where, []).then((data : any) =>{
          let push_data:any=[];
          for(var x = 0; x < data.rows.length; x++) {
            push_data.push(data.rows.item(x));
          }
          resolve(push_data);
        }).catch((error : any) =>{
            reject('table creation'+error);
        });
      });
    }
  }

  // insert and update categories
  add_update_catagories(data){
    return new Promise((resolve,reject)=>{
      this.getCategories('where term_id='+data.term_id).then(res=>{
        let is_row_exist:any=res;
        if(is_row_exist.length<=0){
          this.db.executeSql('INSERT INTO bb_categories (term_id, name, taxonomy, slug, count, term_taxonomy_id, parent, is_auto_expand, category_order) VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?)', 
          [data.term_id, data.name,data.taxonomy, data.slug, data.count, data.term_taxonomy_id, data.parent, data.is_auto_expand, data.category_order]).then(data=>{
          },err=>{
            reject('Insert categories error : '+err);
          })
        } else {
          this.db.executeSql('UPDATE bb_categories SET name=?, taxonomy=?, slug=?, count=?, term_taxonomy_id=?, parent=?, is_auto_expand=?, category_order=? where term_id=?',
           [data.name, data.taxonomy, data.slug, data.count, data.term_taxonomy_id, data.parent, data.is_auto_expand, data.category_order ,data.term_id]).then(data=>{

          },err=>{
            reject('Update categories error : '+err);
          })
        }
      },err=>{
        reject('getting issue while checking categories'+err);
      });
    }).catch((error : any)=>{
      throw new Error('lesson update and insertn in error');
    })
  }

  // get lessons by post_id 
  getLessons(where='',columns='*'){
    if(this.plt.is('cordova')){
      return new Promise((resolve, reject) =>{
        this.db.executeSql('SELECT '+columns+' FROM bb_lessons '+where, []).then((data : any) =>{
          let push_data:any=[];
          for(var x = 0; x < data.rows.length; x++) {
            push_data.push(data.rows.item(x));
          }
          resolve(push_data);
        }).catch((error : any) =>{
            reject('table creation'+error);
        });
      });
    }
  }

  // insert and update lessons
  add_update_lessons(data){
    return new Promise((resolve,reject)=>{
    this.getLessons(' where ID='+data.ID).then(res=>{
        let is_row_exist:any=res;
        if(is_row_exist.length<=0){        
          this.db.executeSql('INSERT INTO bb_lessons (ID, category_id, category_name, category_parent, feature_image, is_favour, lesson_content_type,  overlay_image, pdf, post_date, post_type, sections, title, videos, menu_order) VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)',
          [data.ID, data.category_id, data.category_name, data.category_parent, data.feature_image, data.is_favour, data.lesson_content_type,  data.overlay_image, data.pdf, data.post_date, data.post_type, data.sections, data.title, data.videos, data.menu_order]).then(data=>{
            resolve('Lesson table data inserted');
          },err=>{
            reject('Insert lessons error : '+err);
          })
        } else {
          this.db.executeSql('UPDATE bb_lessons SET category_id=?, category_name=?, category_parent=?, feature_image=?, is_favour=?, lesson_content_type=?, overlay_image=?, pdf=?, post_date=?, post_type=?, sections=?, title=?, videos=?, menu_order=? where ID=?', 
          [data.category_id, data.category_name, data.category_parent, data.feature_image, data.is_favour, data.lesson_content_type,  data.overlay_image, data.pdf, data.post_date, data.post_type, data.sections, data.title, data.videos, data.menu_order, data.ID]).then(data=>{
            resolve('Lesson table data updated');
          },err=>{
            reject('Update lessons error : '+err);
          })
        }
      },err=>{
        reject('getting issue while checking lesson'+err);
      });
    }).catch((error : any)=>{
      throw new Error('lesson update and insertn in error');
    })
  }

  particular_update_lessons(update_field,conditions){
    return new Promise((resolve,reject)=>{
      let where='';
      if(conditions){
        where+=' where '+conditions;
      }
      this.db.executeSql('UPDATE bb_lessons SET '+update_field+where,[]).then(data=>{
      },err=>{
        reject('Update lessons error : '+err);
      })
    })
  }

  // checking image is stored in device or not
  getLocalImages(parent_id=null,post_id=null): Promise<any[]>{
    return new Promise((resolve, reject) =>{
      let where='';
      if(parent_id && post_id){
        where+='where parent_id='+parent_id+' and post_id="'+post_id+'"';
      }
      this.db.executeSql('SELECT * FROM bb_local_images '+where, []).then((data : any) =>{
        let push_data:any=[];
        for(var x = 0; x < data.rows.length; x++) {
          push_data.push(data.rows.item(x));
        }
        resolve(push_data);
      }).catch((error : any) =>{
          reject('checking image '+error);
      });
    });
  }

  // insert local path of image
  add_update_local_images(data,id=null){
    return new Promise((resolve,reject)=>{
      this.getLocalImages(data.parent_id,data.post_id).then(res=>{
        if(res.length<=0){
          this.db.executeSql('INSERT INTO bb_local_images (parent_id, post_id, url) VALUES (?, ?, ?)', [data.parent_id, data.post_id, data.url]).then(data=>{
          },err=>{
            reject('insert image'+err);
          })
        } else {
          this.db.executeSql('UPDATE bb_local_images SET url=? where parent_id=? and post_id=?', [data.url,data.parent_id, data.post_id]).then(data=>{
          },err=>{
            reject('update image'+err);
          })
        }
      })
    })
  }

  getFavorites(columns="*",where){
    if(this.plt.is('cordova')){
      return new Promise((resolve, reject) =>{
        console.log('SELECT '+columns+' FROM bb_favorites '+where);
        this.db.executeSql('SELECT '+columns+' FROM bb_favorites '+where, []).then((data : any) =>{
          let push_data:any=[];
          for(var x = 0; x < data.rows.length; x++) {
            push_data.push(data.rows.item(x));
          }
          resolve(push_data);
        }).catch((error : any) =>{
            reject(error);
        });
      });
    }
  }

  add_update_favorites(user_id,post_id,status){
    return new Promise((resolve,reject)=>{
      this.getFavorites('*',' where user_id='+user_id+' and post_id='+post_id).then(res=>{
        let is_row_exist:any=res;
        if(is_row_exist.length<=0){
          console.log('INSERT INTO bb_favorites (user_id, post_id,status) VALUES (?, ?, ?)', [user_id,post_id,status]);
          this.db.executeSql('INSERT INTO bb_favorites (user_id, post_id,status) VALUES (?, ?, ?)', [user_id,post_id,status]).then(data=>{
            resolve(data)
          },err=>{
            reject('Insert favorite'+err);
          })
        } else {
            console.log('update bb_favorites set status='+status+' where user_id='+user_id+' and post_id='+post_id);
            this.db.executeSql('update bb_favorites set status='+status+' where user_id='+user_id+' and post_id='+post_id, []).then(data=>{
              resolve(data)
            },err=>{
              reject('update favorite'+JSON.stringify(err));
            })
        }
      },err=>{
        reject(err);
      });
    })
  }

  getNotes(user_id,post_id=null,group_by='',extra_query=''){
    if(this.plt.is('cordova')){
      return new Promise((resolve, reject) =>{
        if(post_id){
          extra_query=' and post_id='+post_id;
        } 
        this.db.executeSql('SELECT * FROM bb_notes where user_id='+user_id+extra_query+group_by, []).then((data : any) =>{
          let push_data:any=[];
          for(var x = 0; x < data.rows.length; x++) {
            push_data.push(data.rows.item(x));
          }
          resolve(push_data);
        }).catch((error : any) =>{
            reject(error);
        });
      });
    }
  }

  add_update_notes(data){
    return new Promise((resolev,reject)=>{
      this.db.executeSql('SELECT * FROM bb_notes where note_id='+data.note_id, []).then((res : any) =>{
        if(res.rows.length>0){
            this.db.executeSql('UPDATE bb_notes SET category_id=?, category_name=?, title=?, content=? where note_id=?', 
            [data.category_id, data.category_name, data.title, data.content, data.note_id]).then(data=>{
            },err=>{
              reject(err);
            })    
        } else {
          this.db.executeSql('INSERT INTO bb_notes (note_id, user_id, post_id, category_id, category_name, title, content, lesson_name) VALUES (?, ?, ?, ?, ?, ?, ?, ?)', 
          [data.note_id, data.user_id, data.post_id, data.category_id, data.category_name, data.title, data.content,data.lesson_name]).then(data=>{
          },err=>{
            reject(err);
          })
        }
      });
    })
  }

  deleteNotes(category){
    return new Promise((resolve,reject)=>{
      this.db.executeSql('delete from bb_notes where note_id=?',[category.note_id]).then(data=>{
      },err=>{
        reject(err);
      })
    })
  }

  add_logs(messages){
    return new Promise((resolve,reject)=>{
      this.db.executeSql('INSERT INTO bb_download_logs (messages) VALUES (?)',[messages]).then(data=>{
        resolve(data);
      },err=>{
        reject(err);
      })
    })
  }

  getLogs(){
    return new Promise((resolve,reject)=>{
      this.db.executeSql('SELECT * from bb_download_logs order by id desc',[]).then(data=>{
        let push_data:any=[];
        for(var x = 0; x < data.rows.length; x++) {
          push_data.push(data.rows.item(x));
        }
        resolve(push_data);
      },err=>{
        reject(err);
      })
    })
  }

  mySingleLessonTitle(param1: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.db.executeSql('SELECT title FROM bb_lessons WHERE ID=?',[param1])
      .then((res) => {
        if (res.rows.length > 0) {
          let columnValue = res.rows.item(0).title;
         
           resolve(columnValue);
        }
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  Sync_data(user) {
    this.apiService.getData('get_favour_notes?token='+user.token).subscribe((res1:any)=>{
      if(res1.notes.length>0){
        res1.notes.map(note=>{
          this.add_update_notes(note)
        })
      }   

      if(res1.favorite_posts.length>0){
        res1.favorite_posts.map(element=>{
          this.add_update_favorites(user.user_id,element.ID,element.status);
        })
      } else {
        this.deleteData('bb_favorites','');
      }
      var self=this
      setTimeout(function(){
        self.send_Sync_data(user);
      },500);
    })
  }

  send_Sync_data(user){
    console.log('Sync Now');
    let favorites = [];
    this.getFavorites('post_id, status','where user_id='+user.user_id).then((res:any)=>{
      favorites = res;
    });

    this.getNotes(user.user_id).then((notes: any) => {
      var formvalue: any = {
        token: user.token,
        favorites: favorites,
        notes: notes,
      };
      this.apiService.sendData('Sync_data', formvalue).subscribe((res:any) => {
        console.log('Data Sync');
        if(res.notes.length>0){
          res.notes.map(note=>{
            this.add_update_notes(note)
          })
        }   
  
        if(res.favorite_posts.length>0){
          res.favorite_posts.map(element=>{
            this.add_update_favorites(user.user_id,element.ID,element.status);
          })
        }
      },(err) => {});
    });
  }
}
