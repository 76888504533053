import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-partner-contact',
  templateUrl: './partner-contact.page.html',
  styleUrls: ['./partner-contact.page.scss'],
})
export class PartnerContactPage implements OnInit {
  @Input() partner_id;
  @Input() partner_name;
  contactForm: FormGroup;
  userinfo:any;
  phone:any;
  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    public model : ModalController,
    private storageService: StorageService,
  ) { }

  ngOnInit() {
    this.contactForm = this.fb.group({
      phone: ['',
        Validators.compose([
          Validators.required,
          Validators.minLength(10),
        ]),],
      user_email: ['',
        Validators.compose([
          Validators.required,
          Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')
        ]),],
      message: ['',
        Validators.compose([
          Validators.required,
        ])]
    });
  }

  ionViewWillEnter() {
    this.storageService.getFromNativeStorage('bb:user').then((user) => {
      if (user != null) {
        this.userinfo = user;
        this.contactForm.patchValue(user);
      }
    });
  }

  close_model(){
    this.model.dismiss();
  }

  contactUs(formData) {
    formData.post_id=this.partner_id;
    this.apiService.showLoader();
    formData.token = this.userinfo.token;
    formData.name=this.userinfo.name;
    this.apiService.sendData('submit_request', formData).subscribe(res => {
      this.apiService.dismissLoading();
      this.contactForm.reset();
      this.model.dismiss(true);
    },
      (err) => {
        this.apiService.dismissLoading();
        this.apiService.presentToast('Something went wrong! Please try again');
      }
    );
  }
}
