import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { DatabaseFunctionsService } from './database-functions.service';
import { DownloadFilesService } from './download-files.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadDataService {
  posts:any=[]
  constructor(
    public apiService : ApiService,
    public storageService : StorageService,
    public db : DatabaseFunctionsService,
    public downloadfile : DownloadFilesService
  ) { 
   
  }
}