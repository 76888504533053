import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { HeaderComponent } from './header/header.component';
import { SearchComponent } from './search/search.component';

@NgModule({
  declarations: [   
    HeaderComponent,
    SearchComponent],
  imports: [
    CommonModule,
    IonicModule
  ],
  entryComponents:[
    HeaderComponent,
    SearchComponent
  ],
  exports:[
    HeaderComponent,
    SearchComponent
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA]
})
export class SharedModule { }
