import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, LoadingController } from '@ionic/angular';
import { InAppBrowserOptions, InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { ApiService } from './api.service';
@Injectable({
  providedIn: 'root'
})
export class AlertControllerService {
  public browser: any
  constructor(
    private alertController: AlertController,
    public loadingCtrl: LoadingController,
    public router: Router,
    public iab: InAppBrowser,
    public apiService: ApiService
  ) { }


  presentAlert(alertMessage: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        cssClass: 'artstock_alert_2',
        message: alertMessage,
        mode: 'ios',
        buttons: [
          {
            text: 'Ok',
            role: 'cancel',
            handler: () => resolve(false)
          }
        ]
      });

      await alert.present();
    });
  }

  presentAlertWithHeader(alertHeader: string, alertMessage: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        cssClass: 'artstock_alert_2',
        header: alertHeader,
        message: alertMessage,
        mode: 'ios',
        buttons: [
          {
            text: 'Yes',
            handler: () => resolve(true)
          },
          {
            text: 'No',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => resolve(false)
          }
        ]
      });

      await alert.present();
    });
  }

  presentAlertWithButtons(alertMessage: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        cssClass: 'artstock_alert_2',
        message: alertMessage,
        mode: 'ios',
        buttons: [
          {
            text: 'Ok',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => resolve(true)
          }
        ]
      });

      await alert.present();
    });
  }

  showLoader(msg:any='') {
    if(msg==''){
      msg = "Loading..."; 
    }
    this.loadingCtrl.create({
      message:msg
    }).then((response) => {
      response.present();
    });
  }
  // Dismiss loader
  dismissLoader() {
    this.loadingCtrl.dismiss().then((response) => {
    }).catch((err) => {
    });
  }

  redirect_to_website(alertMessage: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        cssClass: 'artstock_alert_2',
        message: alertMessage,
        mode: 'ios',
        backdropDismiss:false,
        buttons: [
          {
            text: 'OK',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              window.open('https://hubs.ly/H0mj_j80','_blank');
              // window.location.reload();
            }
          }
        ]
      });

      await alert.present();
    });
  }

  async subscriptionAlert() {
    const confirm = await this.alertController.create({
      header: 'No Subscription Found: ',
      message: "We couldn't find an active subscription associated with your account. Please <a href= 'mailto: help@aana.com'> contact support</a> for further assistance.",
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            this.router.navigateByUrl('login');
            confirm.dismiss();
            console.log('Confirm Okay.');
            const options: InAppBrowserOptions = {
              location: "yes",
              fullscreen: "yes",
              toolbarposition: "top",
              closebuttoncaption: "Cancel",
              clearcache: "yes",
              clearsessioncache: "yes",
              hidden: "no",
              hideurlbar: "no",
              toolbar: "yes",
              hidenavigationbuttons: "no",
              zoom: "yes",
            };
            this.browser = this.iab.create(this.apiService.getURL(), '_blank', options);
            this.browser.show();
          }
        }
      ]
    });
    await confirm.present();
  }
}
