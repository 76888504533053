
import { Injectable } from '@angular/core';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { Platform } from '@ionic/angular';
import { Observable, from } from 'rxjs';
import { DatabaseFunctionsService } from './database-functions.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadFilesService {

  constructor(
    public file : File,
    public platform : Platform,
    public transfer : FileTransfer,
    public db : DatabaseFunctionsService
  ) { }

  async downloadDoc(url,post_id,file_type) {
    if(!url){
      return false;
    }
    if(window.navigator.onLine){
      const downloadPath = (this.platform.is('android')) ? this.file.externalRootDirectory : this.file.documentsDirectory;
      // console.log('downloadPath', downloadPath);
      try {
        const dirExists = await this.file.checkDir(downloadPath, 'BlockBuddy/'+post_id+'/'+file_type+'/'+url.split('/').pop());
        // console.log('dirExists', dirExists);
        return await this.download_file(url,downloadPath,post_id,file_type);
      } catch {
        // console.log('createDir called');
        const createdDir = this.file.createDir(downloadPath, "BlockBuddy", true);
        // console.log('createdDir', createdDir);
        return await this.download_file(url,downloadPath,post_id,file_type);
      }
      
    } else {
      throw new Error('Failed to load images due to network issues');
    };
  }

  async download_file(url,downloadPath,post_id,file_type){
      var self=this;
      const fileTransfer: FileTransferObject = this.transfer.create();
      try {
        const fileExists = await this.file.checkFile(downloadPath + '/BlockBuddy/'+post_id+'/'+file_type,url.split('/').pop());
        if(file_type!=='pdf'){
          return (<any>window).Ionic.WebView.convertFileSrc(downloadPath+'/BlockBuddy/'+post_id+'/'+file_type+'/'+url.split('/').pop());
        } 
      } catch {
        // if file not exist in directory then download it
        try {
          const transfer = await fileTransfer.download(url,downloadPath + '/BlockBuddy/'+post_id+'/'+file_type+'/'+url.split('/').pop());
          if (!!transfer) {
            if(file_type==='pdf'){
              return transfer.toURL();
            } else {
              return (<any>window).Ionic.WebView.convertFileSrc(downloadPath+'/BlockBuddy/'+post_id+'/'+file_type+'/'+url.split('/').pop());
            }
          } 
        }catch (err) {
          // return 'File Error'+ err;
          // this.download_file(url,downloadPath,post_id,file_type);

        }
      }
  }
  
 async delete_directory(post_id){
  const downloadPath = (this.platform.is('android')) ? this.file.externalRootDirectory : this.file.documentsDirectory;

  await this.file.removeDir(downloadPath,'BlockBuddy/'+post_id);
 }
}
