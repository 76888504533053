import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Events } from 'src/app/services/common/events.service';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  constructor(
    public events:Events,
    public apiService:ApiService,
    public router : Router
  ) { }

  ngOnInit() {}

  reload(){
    this.events.publish('reload',1);
    this.events.publish('check_keyword',1);
    this.router.navigate(['/tabs/home']);
  }
}
